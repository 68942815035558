<script setup>
import { useStore } from 'vuex'
import LogoutButton from '@/components/LogoutButton.vue'

const store = useStore()
</script>

<template>
  <nav v-if="store.getters['auth/loggedIn']" class="m-4">
    <router-link to="/listeners">Listeners</router-link> |
    <router-link to="/labels">Labels</router-link> |
    <router-link to="/creators">Creators</router-link> |
    <router-link to="/requests">Requests</router-link> |
    <router-link to="/submissions">Submissions</router-link> |
    <LogoutButton />
  </nav>
  <router-view />
</template>
