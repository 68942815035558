<script setup>
import { ref } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const loading = ref(false)
const store = useStore()
const router = useRouter()

const logout = () => {
  if(loading.value)return;

  loading.value = true
  axios.post(`${process.env.VUE_APP_API_URL}/logout`, {}, {
    headers: {
      Authorization: "Bearer " + store.state.auth.token
    }
  })
  .then((response) => {
    console.log(response)
    router.replace({ name: 'login' })

  })
  .catch((error) => {
    console.log(error)
  })
  .finally(() => {
    loading.value = false
    window.localStorage.removeItem("token")
    window.localStorage.removeItem("user")
    store.commit('auth/DELETE_TOKEN')
    store.commit('auth/DELETE_USER')
  })
}
</script>

<template>
  <button
    :disabled="loading"
    @click="logout"
    :class="{'cursor-not-allowed': loading, 'cursor-pointer': !loading}">
    Logout
  </button>
</template>