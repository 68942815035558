<script setup>
import { defineProps } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'

defineProps({
  users: Array
})

const store = useStore()

const approve = async (user) => {
  if(!window.confirm(`Are you sure you want to approve ${user.email}?`))return

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/admin/requests/approve`, {
      id: user.id
    }, {
      headers: {
        Authorization: "Bearer " + store.state.auth.token
      }
    })
    console.log(response)
    alert(response.data.user.link)
  } catch(error) {
    console.log(error)
  }
}

const deny = async (user) => {
  if(!window.confirm(`Are you sure you want to deny access for ${user.email}?`))return

  try {
    const response = await axios.post(`${process.env.VUE_APP_API_URL}/admin/requests/deny`, {
      id: user.id
    }, {
      headers: {
        Authorization: "Bearer " + store.state.auth.token
      }
    })
    console.log(response)
  } catch(error) {
    console.log(error)
  }
}
</script>

<template>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Email</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Company</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Info</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created at</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Link</th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
        </th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-for="user in users" :key="user.id">
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ user.email }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.company }}</td>
        <td class="whitespace-wrap px-3 py-4 text-sm text-gray-500">{{ user.info }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ new Date(user.created_at).toLocaleString() }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ user.link }}</td>
        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <form @submit.prevent="approve(user)" class="inline">
            <button class="px-2 py-1 bg-green-400 hover:bg-green-500 rounded">Approve</button>
          </form> /
          <form @submit.prevent="deny(user)" class="inline">
            <button class="px-2 py-1 bg-red-400 hover:bg-red-500 rounded">Deny</button>
          </form>
        </td>
      </tr>
    </tbody>
  </table>
</template>