
const state = {
  user: window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")) : null,
  token: window.localStorage.getItem("token") || null,
}

const getters = {
  loggedIn(state) {
    return (
      state.token !== null &&
      state.token !== "undefined" &&
      state.user !== null &&
      state.user !== "undefined"
    );
  },
}

const mutations = {
  SET_TOKEN(state, token){
    state.token = token
    window.localStorage.setItem("token", token)
  },
  SET_USER(state, user){
    state.user = user
    window.localStorage.setItem("user", JSON.stringify(user))
  },
  DELETE_TOKEN(state){
    state.token = null
  },
  DELETE_USER(state){
    state.user = null
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};